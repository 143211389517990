import { useParams } from "react-router-dom";
import { FALLBACK, PARAMS } from "../constants/Params";
import { useTranslation } from "react-i18next";

export function useLang(): string {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const params = useParams();
  return (resolvedLanguage ?? params[PARAMS.LANGUAGE] ?? FALLBACK[PARAMS.LANGUAGE]).toLowerCase();
}
