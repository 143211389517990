import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { PARAMS } from "../../router/constants/Params";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { isOpenCategory } from "../../category/scripts/navigation";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { useLinkToCategoryBuilder } from "../../category/scripts/link";
import { getRelatedCategory } from "../../graphcms/scripts/category";
import { hasDisplayableItems } from "../../category/scripts/filter";
import UserNotifications from "../../layout/components/UserNotifications";

const MobileHeader: React.FC = () => {
  const params = useParams();
  const getLinkToCategory = useLinkToCategoryBuilder();

  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);

  let selectedLabel = i18next.t("CATEGORY", { count: 1 });
  let selectedId = "";

  function handleCategoryChange(ev) {
    if (!ev?.target?.value) {
      return;
    }

    const category = getRelatedCategory(categoriesTree, ev.target.value);

    if (!category) {
      return;
    }

    boundPushLocObj({ pathname: getLinkToCategory(category) });
  }

  const options = categoriesTree.map(category => {
    const isSelected = category.id === params[PARAMS.CATEGORY_ID];

    if (isSelected) {
      selectedLabel = category.name;
      selectedId = category.id;
    }

    const isOpen = isOpenCategory(category, params);

    if (isOpen) {
      return (
        <React.Fragment key={category.id}>
          {/*this is the main category*/}
          <option key={category.id} value={category.id} aria-selected={isSelected}>
            {category.name}
          </option>

          {/*this includes all child categories */}
          {category.categories
            .filter(category => hasDisplayableItems(category, params))
            .map(category => {
              const isSelected = category.id === params[PARAMS.CATEGORY_ID];

              if (isSelected) {
                selectedLabel = category.name;
                selectedId = category.id;
              }

              return (
                <option key={category.id} value={category.id} aria-selected={isSelected}>
                  &#160;&#160;&#160;&#160;{category.name}
                </option>
              );
            })}
        </React.Fragment>
      );
    }

    return (
      <option key={category.id} value={category.id} aria-selected={isSelected}>
        {category.name}
      </option>
    );
  });

  return (
    <div className="mobile-header">
      <div className="select-wrap">
        <select
          className="select"
          id="select-category"
          name="select-category"
          aria-expanded="false"
          aria-hidden="false"
          value={selectedId}
          aria-label={selectedLabel}
          onChange={handleCategoryChange}
          onBlur={handleCategoryChange}
        >
          {/*at landing page when no category is selected */}
          <option hidden value={""} aria-hidden="true">
            {selectedLabel}
          </option>
          {options}
        </select>
        <UserNotifications />
      </div>
    </div>
  );
};

export default MobileHeader;
