import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Left from "../../layout/components/Col/Left";
import Right from "../../layout/components/Col/Right";
import i18next from "i18next";
import CartOverlay from "../../cart/components/CartOverlay";
import NavAccount from "./NavAccount";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getCustomerOrders } from "../actions/accountActions";
import SearchOverlay from "../../search/components/SearchOverlay";
import OrderListItem from "./OrderListItem";
import MobileHeaderAccount from "./MobileHeaderAccount";
import { AccountFallback } from "./AccountFallback";
import useInitCustomer from "../hooks/useInitCustomer";

const AccountOrders: React.FC = () => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const accountOrders = useSelector((state: State) => state.account.orders);
  useInitCustomer();

  useEffect(() => {
    getCustomerOrders();
  }, [accountCustomer]);

  if (accountCustomer === null) {
    return <AccountFallback />;
  }

  const customerId = accountCustomer.id;
  const years = accountOrders.map(order => new Date(order.attributes.placed_at).getFullYear());
  const uniqueYears = [...new Set(years)];

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeaderAccount activeLi={PATH.ORDERS} customerId={accountCustomer.id} />
        <Left>
          <NavAccount activeLi={PATH.ORDERS} customerId={customerId} />
        </Left>
        <Middle>
          <div className="list-wrap">
            <div className="list-title-wrap">
              <h1 className="col-title">
                {`${i18next.t("ORDER", { count: accountOrders.length })} (${accountOrders.length})`}
              </h1>

              <div className="search ">
                <form className="form search__form" action="#">
                  <input
                    type="text"
                    className="input--text"
                    id="search--order"
                    name="search--order"
                    defaultValue=""
                    placeholder={`${i18next.t("FILTER")} ...`}
                    aria-required="false"
                    aria-label={`${i18next.t("FILTER")} ...`}
                  />
                  <a
                    href="https://thommenmedical.com"
                    className="icon-btn icon-btn__search"
                    role="button"
                    tabIndex={0}
                  >
                    <span className="visuallyhidden">{i18next.t("FILTER")}</span>
                  </a>
                </form>
              </div>
            </div>
          </div>

          {uniqueYears
            .sort((a, b) => b - a)
            .map((year, index) => {
              return (
                <div className="list-wrap" key={index}>
                  <div className="list-item-single list-item-single--header">
                    <p className="list-item__text">{year}</p>
                    <div className="list-item__text">
                      {/*
                    TODO: link to PDF
                    <div
                      className="list-item--link"
                      title={i18next.t("ANNUAL REVIEW PDF DOWNLOAD")}
                    >
                      {i18next.t("ANNUAL REVIEW PDF DOWNLOAD")}
                    </div>
                    */}
                    </div>
                  </div>
                  {accountOrders
                    .filter(order => year === new Date(order.attributes.placed_at).getFullYear())
                    .map(order => (
                      <OrderListItem order={order} key={order.attributes.number} />
                    ))}
                </div>
              );
            })}
        </Middle>
        <Right removeOnCartClosed={true}>
          <CartOverlay />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default AccountOrders;
