import { getState } from "../../state/scripts/state";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { logout } from "../../login/actions/loginActions";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";

export async function handleError401(e) {
  const errors = e.response?.errors ?? null;
  if (errors === null) {
    return;
  }
  for (let i = 0; i < errors.length; i++) {
    let error = errors[i];
    if (error.status === "401") {
      const loginState = getState().login;
      let ownerType: string = OWNER_TYPE.CUSTOMER;
      if (loginState.token) {
        ownerType = loginState.token.owner_type;
      }
      await logout();
      if (ownerType === OWNER_TYPE.USER) {
        boundPushLocObj({
          pathname: `${getLangCountry()}/${PATH.SALES_AGENT}/${PATH.LOGIN}`,
        });
      } else {
        boundPushLocObj({ pathname: `${getLangCountry()}/${PATH.LOGIN}` });
      }
      return;
    }
  }
}
