import React, { useState } from "react";
import i18next from "i18next";
import { SEARCH } from "../../router/constants/Search";
import { getValuesInUrlList } from "../../router/scripts/search";
import PfSelectOption from "./PfSelectOption";
import PfTag from "./PfTag";
import { useGetPfsQuery } from "../../graphcms/services/pfApi";

const Focus: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { data: pfs = [], isLoading } = useGetPfsQuery();

  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <div className="focus">
      <p className="focus__label">{`${i18next.t("FOCUS")}:`}</p>
      {pfs
        .filter(pf => focus.includes(pf.name))
        .map(pf => (
          <PfTag key={pf?.name} pfName={pf?.name} />
        ))}
      {focus.length !== pfs.length || isLoading ? (
        <div className={`select-custom ${open ? "open" : ""}`} onClick={toggleOpen}>
          <div className="select__placeholder">{`${i18next.t("ADD FOCUS")}`}</div>
          <div className="select-dropdown">
            <ul className="select-dropdown__options" role="tree" aria-hidden={!open}>
              {pfs
                .filter(pf => !focus.includes(pf.name))
                .map(pf => (
                  <PfSelectOption key={pf.name} pfName={pf?.name} />
                ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Focus;
