import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Left from "../../layout/components/Col/Left";
import Right from "../../layout/components/Col/Right";
import i18next from "i18next";
import CartOverlay from "../../cart/components/CartOverlay";
import NavAccount from "../../account/components/NavAccount";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SearchOverlay from "../../search/components/SearchOverlay";
import MobileHeaderAccount from "../../account/components/MobileHeaderAccount";
import ConsignmentStockBreadcrumb from "./ConsignmentStockBreadcrumb";
import { AccountFallback } from "../../account/components/AccountFallback";
import { OVERLAY } from "../../layout/constants/Overlay";
import OverlayPopupQRCode from "../../scan/components/OverlayPopupQRCode";
import { toast } from "react-toastify";
import { getConsignmentStockQuantityRecord, getLineItemsQuantityMap } from "../scripts/inventory";
import { getIdFromSkuAndBatchNumber } from "consignment-stock/scripts/id";
import { addLineItem, addNewScanSkus } from "../actions/consignmentStockActions";
import { getState } from "../../state/scripts/state";
import { logSentryAndConsole } from "../../common/scripts/logger/log";
import scanFromCst from "../../consignment-stock/services/scanFromCst";
import { setOverlayOpenAction } from "../../layout/actions/layoutActions";
import { ConsignmentStockResponse, ScannedInventory } from "../vo/ConsignmentStock";
import OverlayPopupScanFromCstMobile from "../../scan/components/OverlayPopupScanFromCstMobile";
import OverlayPopupScanFromCstDesktop from "../../scan/components/OverlayPopupScanFromCstDesktop";
import { resetScannedReceivedInventory, setScanId } from "../slices/consignmentStockSlice";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { extractParsedCodeItems, getParsedCodeItems } from "../scripts/scan";
import ConsignmentStockMain from "consignment-stock/components/ConsignmentStockMain";
import { Customer } from "../../commercelayer/vo/customers";
import { useInventory } from "consignment-stock/hooks";
import "consignment-stock/css/index.css";
import useInitCustomer from "../../account/hooks/useInitCustomer";

// this should belong to a desktop device
let intervalScanningReceivingDevice;

async function attemptAddToOrder(
  scannedInventory: ScannedInventory,
  setLocalScannedStore: (any) => void,
  consignmentStock: ConsignmentStockResponse | undefined,
) {
  if (!consignmentStock) return;

  const { sku, batchNumber, expiry } = scannedInventory;
  const id = getIdFromSkuAndBatchNumber(sku, batchNumber);
  const lineItemsSkus = getState()?.consignmentStock?.order?.lineItemsSkus;
  const skuOrderQuantityMap = getLineItemsQuantityMap(lineItemsSkus);
  const consignmentStockQuantityRecord = getConsignmentStockQuantityRecord(consignmentStock);

  // not in consignmentStock
  if (!consignmentStockQuantityRecord[id]) {
    const msg = i18next.t("SCANNED PRODUCT NOT IN STOCK");
    toast.warn(msg, {
      position: "top-center",
      autoClose: 3000,
    });
    return;
  }

  // not able to add more
  // already have the maximum of numbers added to order that are available in the consignmentStock
  if (skuOrderQuantityMap[id] >= consignmentStockQuantityRecord[id]) {
    const msg = i18next.t("SCANNED PRODUCT MAXIMUM IN STOCK");
    toast.warn(msg, {
      position: "top-center",
      autoClose: 3000,
    });
    return;
  }

  // all tests passed and can be added to list
  await addLineItem(sku, batchNumber);
  setLocalScannedStore(scannedInventory => [...scannedInventory, { sku, batchNumber, expiry }]);

  toast.info(i18next.t("SCANNED PRODUCT ADDED TO ORDER"), {
    position: "top-center",
    autoClose: 2000,
  });
}

const ConsignmentStock: React.FC = () => {
  const customer: Customer | null = useSelector((state: State) => state.account.customer);
  const scanId = useSelector((state: State) => state.consignmentStock.scanId);
  const scannedReceivedInventory = useSelector(
    (state: State) => state.consignmentStock.scannedReceivedInventory,
  );
  const [scannedInventory, setScannedInventory] = useState<ScannedInventory[]>([]);
  const [scannedInventoryAddedToOrder, setScannedInventoryAddedToOrder] = useState<
    ScannedInventory[]
  >([]);
  const productsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const { consignmentStock, error } = useInventory({ refetchOnMountOrArgChange: true });
  useInitCustomer();

  // this is the effect running usually on a desktop device after clicking on scan button
  // it takes the role of receiving the scanned skus device
  useEffect(() => {
    if (!scanId) {
      return;
    }

    async function handleReceivingProductSkus() {
      const overlayOpen = getState().layout.overlayOpen;
      // not active
      if (!scanId) {
        return;
      }

      const response = await scanFromCst.getScanSkus(scanId);

      if (!response) {
        return;
      }

      if (!response?.success) {
        return;
      }

      if (!response?.data?.scanSkus) {
        return;
      }

      // include the scanSkus here
      const { scanSkus } = response.data;

      // nothing as been scanned so far or results are reset
      // not null as scanSkus indicates that the remoteScanning is ready
      if (scanSkus === null) {
        return;
      }

      // transition from QRSCAN to RESULTS
      if (overlayOpen === OVERLAY.QRSCAN) {
        setOverlayOpenAction(OVERLAY.CST_DESKTOP_RESULTS);
      }

      if (scanSkus?.length === 0) {
        return;
      }

      // we use redux-store because local state has issues with interval
      // https://answerbun.com/stack-overflow/react-interval-using-old-state-inside-of-useeffect/
      addNewScanSkus(scanSkus);
    }

    // start interval listening if products have been scanned
    if (!intervalScanningReceivingDevice) {
      intervalScanningReceivingDevice = setInterval(() => {
        handleReceivingProductSkus().then();
      }, 1500);
    }
  }, [scanId]);

  useEffect(
    function evaluateScannedReceivedInventoryForOrder() {
      async function _evaluateScannedReceivedInventoryForOrder() {
        if (!intervalScanningReceivingDevice) return;
        if (!scannedReceivedInventory) return;
        if (!consignmentStock) return;
        if (consignmentStock.length === 0) return;
        if (scannedReceivedInventory.length === 0) return;

        const latestScannedInventory = [...scannedReceivedInventory].pop();
        if (!latestScannedInventory) return;

        await attemptAddToOrder(
          latestScannedInventory,
          setScannedInventoryAddedToOrder,
          consignmentStock,
        );
      }

      _evaluateScannedReceivedInventoryForOrder().then();
    },
    [scannedReceivedInventory, consignmentStock],
  );

  const onScan = useCallback(
    function (scanResult: string) {
      const parsedCodeItems = getParsedCodeItems(scanResult);

      if (!parsedCodeItems) {
        toast.error("Something went wrong while scanning. Please reconnect and try again.", {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }

      const { gtin, batchNumber, expiry } = extractParsedCodeItems(parsedCodeItems);

      if (!batchNumber || !gtin) {
        logSentryAndConsole("Barcode decoding went wrong", "error");
        return;
      }

      const products = Object.values(productsIndex);
      const product = products.find(product => product.gtin13 === gtin);

      if (!product) {
        logSentryAndConsole(`No Product Found with gtin ${gtin} while scanning`, "error");
        return;
      }

      const { sku } = product;

      attemptAddToOrder({ sku, batchNumber, expiry }, setScannedInventory, consignmentStock).then();
    },
    [consignmentStock, productsIndex],
  );

  const shutdownScanning = useCallback(async () => {
    dispatch(setScanId(null));
    dispatch(resetScannedReceivedInventory());
    // reset all intervals
    clearInterval(intervalScanningReceivingDevice);
    intervalScanningReceivingDevice = null;

    setOverlayOpenAction(null);
    setScannedInventory([]);
    setScannedInventoryAddedToOrder([]);

    // reset server scanned state to null
    if (!scanId) return;
    await scanFromCst.resetScanSkus(scanId);
  }, [scanId]);

  // handle shutdown on scan page leaving
  useEffect(
    function cleanup() {
      return () => {
        // shutdown when leaving consignment stock
        if (!window.location.pathname.includes(PATH.CONSIGNMENT_STOCK)) {
          shutdownScanning().then();
        }
      };
    },
    [shutdownScanning],
  );

  if (customer === null) {
    return <AccountFallback />;
  }

  return (
    <Layout>
      <OverlayPopupScanFromCstMobile
        onScan={onScan}
        scannedInventory={scannedInventory}
        shutdownScanning={shutdownScanning}
      />
      <OverlayPopupScanFromCstDesktop
        scannedInventory={scannedInventoryAddedToOrder}
        shutdownScanning={shutdownScanning}
      />
      <OverlayPopupQRCode scanId={scanId ?? ""} shutdownScanning={shutdownScanning} />
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeaderAccount activeLi={PATH.CONSIGNMENT_STOCK} customerId={customer.id} />
        <Left>
          <NavAccount activeLi={PATH.CONSIGNMENT_STOCK} customerId={customer.id} />
        </Left>
        <Middle>
          <h1 className="col-title">{i18next.t("CONSIGNMENT STOCK")}</h1>
          {error ? (
            <>
              {/* @ts-ignore */}
              {JSON.stringify(error?.data?.message)}
            </>
          ) : (
            <>
              <ConsignmentStockBreadcrumb activeStep={0} />
              <ConsignmentStockMain />
            </>
          )}
        </Middle>
        <Right removeOnCartClosed={true}>
          <CartOverlay />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default ConsignmentStock;
