import React from "react";
import State from "../../state/vo/State";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import GridText from "./GridText";
import GridImg from "./GridImg";
import { PARAMS } from "../../router/constants/Params";
import { useLinkToCategoryBuilder } from "../scripts/link";
import i18next from "i18next";
import { scrollTop } from "../../layout/scripts/scroll";
import LandingTeaserIconImg from "./LandingTeaserIconImg";

import imgTeaserConfiguratorIcon from "../../templates/public/images/layout/circle-configurator-blue.svg";
import imgTeaserConfigurator1x from "../../templates/public/images/content/Zaehne.png";
import imgTeaserConfigurator2x from "../../templates/public/images/content/Zaehne_retina.png";
import imgTeaserConfiguratorMobile1x from "../../templates/public/images/content/Zaehne_mobile.png";
import imgTeaserConfiguratorMobile2x from "../../templates/public/images/content/Zaehne_retina_mobile.png";
import imgTeaserScanIcon from "../../templates/public/images/layout/circle-scan-blue.svg";
import imgTeaserScan1x from "../../templates/public/images/content/Smartphone.png";
import imgTeaserScan2x from "../../templates/public/images/content/Smartphone_retina.png";
import imgTeaserScanMobile1x from "../../templates/public/images/content/Smartphone_mobile.png";
import imgTeaserScanMobile2x from "../../templates/public/images/content/Smartphone_retina_mobile.png";
import imgTeaserCst1x from "../../templates/public/images/content/1x_Cst_Teaser_KeyVisual.png";
import imgTeaserCst2x from "../../templates/public/images/content/2x_Cst_Teaser_KeyVisual.png";
import imgTeaserCstMobile1x from "../../templates/public/images/content/1x_Cst_Teaser_KeyVisual_Mobile.png";
import imgTeaserCstMobile2x from "../../templates/public/images/content/2x_Cst_Teaser_KeyVisual_Mobile.png";
import { PATH } from "../../router/constants/Path";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { useShowConsignmentStock } from "../../consignment-stock/hooks";
import LandingTeaser from "./LandingTeaser";
import { hasDisplayableChildCategory } from "../scripts/filter";
import { Category } from "../../graphcms/vo/graphCMS";

type Props = {
  categoriesTree: Category[];
};

const Landing: React.FC<Props> = ({ categoriesTree }) => {
  const params = useParams();
  const langCountry = useLangCountry();
  const showConsignmentStock = useShowConsignmentStock();
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const getLinkToCategory = useLinkToCategoryBuilder();

  // parent category is selected and therefore Landing not rendered
  if (params[PARAMS.CATEGORY_ID]) {
    return null;
  }

  return (
    <>
      <div className="grid-wrap">
        <LandingTeaserIconImg
          label={i18next.t("CONFIGURATOR")}
          title={i18next.t("CONFIGURATOR TSR TITLE")}
          button={i18next.t("CONFIGURATOR TSR BUTTON")}
          linkTo={`${langCountry}/${PATH.CONFIGURATOR}`}
          imgTeaserIcon={imgTeaserConfiguratorIcon}
          imgTeaser1x={imgTeaserConfigurator1x}
          imgTeaser2x={imgTeaserConfigurator2x}
          imgTeaserMobile1x={imgTeaserConfiguratorMobile1x}
          imgTeaserMobile2x={imgTeaserConfiguratorMobile2x}
        />
        <LandingTeaserIconImg
          label={i18next.t("SCAN-TO-CART")}
          title={i18next.t("SCAN-TO-CART TSR TITLE")}
          button={i18next.t("SCAN-TO-CART TSR BUTTON")}
          linkTo={`${langCountry}/${PATH.SCANTOCART}`}
          imgTeaserIcon={imgTeaserScanIcon}
          imgTeaser1x={imgTeaserScan1x}
          imgTeaser2x={imgTeaserScan2x}
          imgTeaserMobile1x={imgTeaserScanMobile1x}
          imgTeaserMobile2x={imgTeaserScanMobile2x}
        />
        {showConsignmentStock && accountCustomer?.id && (
          <LandingTeaser
            label={i18next.t("CONSIGNMENT STOCK")}
            title={i18next.t("CONSIGNMENT STOCK TSR TITLE")}
            button={i18next.t("CONSIGNMENT STOCK TSR BUTTON")}
            linkTo={`${langCountry}/account/${accountCustomer.id}/consignment-stock`}
            imgTeaser1x={imgTeaserCst1x}
            imgTeaser2x={imgTeaserCst2x}
            imgTeaserMobile1x={imgTeaserCstMobile1x}
            imgTeaserMobile2x={imgTeaserCstMobile2x}
          />
        )}
      </div>

      <div className="grid-wrap">
        {categoriesTree
          .filter(category => !category.hidden)
          .filter(category => hasDisplayableChildCategory(category, params))
          .map(category => (
            <Link
              key={category.id}
              className="grid grid-1-3"
              to={getLinkToCategory(category)}
              onClick={() => scrollTop()}
            >
              <GridImg handle={category?.image?.handle} alt={category?.name} />
              <GridText item={category} />
            </Link>
          ))}
      </div>
    </>
  );
};

export default Landing;
