import { getLinkToProductGroup } from "../../category/scripts/link";
import { Product, ProductGroup } from "../../graphcms/vo/graphCMS";
import { getProductDirectLinkSkuSlug } from "../../product/scripts/link";
import { getCategoryId, getProductGroupId } from "../../router/scripts/params";
import i18next from "i18next";
import {
  isIndirectProductView,
  isProductGroupView,
  isProductView,
} from "../../router/scripts/path";
import { HYGRAPH_ASSET_ORIGIN } from "../../common/scripts/config/configuration";

export const getProductStructuredData = function (
  productGroup,
  product: Product | null,
  isVariant: boolean,
) {
  if (product === null) {
    return {};
  }

  const url = `${window.location.origin}${getProductDirectLinkSkuSlug(
    product?.sku,
    product?.slug,
  )}`;
  const productStructuredData: any = {
    "@context": "http://schema.org",
    "@type": `Product`,
    "@id": `${url}#product`,
    name: product.name,
    url: url,
    gtin13: product.gtin13,
    brand: getStructuredDataBrand(),
    description: getStructuredDataProductDescription(product),
  };
  if (product.sku) {
    productStructuredData.sku = product.sku;
    productStructuredData.mpn = product.sku;
  }
  if (getStructuredDataImage(productGroup, product)) {
    productStructuredData.image = getStructuredDataImage(productGroup, product);
  }
  if (isVariant) {
    productStructuredData.isVariantOf = [
      getProductGroupStructuredData(productGroup, product, false),
    ];
  }
  return productStructuredData;
};

export const getProductGroupStructuredData = function (
  productGroup: ProductGroup,
  products,
  isVariant: boolean,
) {
  const url = `${window.location.origin}${getLinkToProductGroup(getCategoryId(), productGroup)}`;
  const structuredDataProductGroup: any = {
    "@context": "http://schema.org",
    "@type": "ProductGroup",
    "@id": `${url}#productgroup`,
    url: url,
    description: getStructuredDataProductGroupDescription(productGroup),
    name: getStructuredDataProductGroupName(productGroup),
    productGroupID: getProductGroupId(),
  };

  if (isVariant) {
    structuredDataProductGroup.hasVariant = products
      .filter(product => {
        return product.productGroup?.id ?? null === productGroup.id;
      })
      .map((product, index) => {
        return getProductStructuredData(productGroup, product, false);
      });
  }
  return structuredDataProductGroup;
};

export const getStructuredDataProductGroupDescription = function (productGroup) {
  return `${productGroup?.name ?? ""} ${productGroup?.nameSuffix ?? ""}`;
};

export const getStructuredDataProductGroupName = function (productGroup) {
  return `${productGroup.name ?? ""} `;
};

export const getStructuredDataProductDescription = function (product: Product) {
  return `${product.name}  ${product.nameSuffix ?? ""}`;
};

export const getStructuredDataProductName = function (product: Product) {
  return product?.name;
};

export const getStructuredDataBrand = function () {
  const brand: object = {
    "@type": "Organization",
    name: "Thommen Medical",
    url: `${i18next.t("URL WEB ROOT")}`,
  };
  return brand;
};

export const getStructuredDataImage = function (
  productGroup: ProductGroup,
  product: Product | null,
) {
  if (product?.image?.handle) {
    return `${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:300,width:300/${product?.image.handle}`;
  } else if (productGroup?.image?.handle) {
    return `${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:300,width:300/${productGroup.image.handle}`;
  } else return null;
};

export const getUrl = function (productGroup: ProductGroup, product?: Product) {
  if (isProductView() || isIndirectProductView()) {
    return `${window.location.origin}${getProductDirectLinkSkuSlug(product?.sku, product?.slug)}`;
  } else if (isProductGroupView()) {
    return `${window.location.origin}${getLinkToProductGroup(getCategoryId(), productGroup)}`;
  } else {
    return "";
  }
};
