import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import i18next from "i18next";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { placeConsignmentStockOrder, setGtcAction } from "../actions/consignmentStockActions";
import { Addresses } from "../../commercelayer/vo/addresses";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import LineItemList from "../../cart/components/LineItemList";
import OrderAddressSection from "../../order/components/OrderAddressSection";
import OrderShippingPaymentSection from "../../order/components/OrderShippingPaymentSection";
import OrderSumList from "../../order/components/OrderSumList";
import OrderTotal from "../../order/components/OrderTotal";
import { Customer } from "../../commercelayer/vo/customers";
import CountrySpecific from "../../common/constants/CountrySpecific";
import { useCountry } from "../../router/hooks/useCountry";
import ConsignmentStock from "../../state/vo/ConsignmentStock";
import MobileHeaderAccount from "../../account/components/MobileHeaderAccount";
import { PATH } from "../../router/constants/Path";
import Left from "../../layout/components/Col/Left";
import NavAccount from "../../account/components/NavAccount";
import ConsignmentStockBreadcrumb from "./ConsignmentStockBreadcrumb";
import { getPaymentMethod, getShippingMethod } from "../../checkout/scripts/shippingpayment";
import { getBillingAddress, getShippingAddress } from "../../checkout/scripts/addresses";
import OrderProcessingButton from "../../checkout/components/OrderProcessingButton";
import { AccountFallback } from "../../account/components/AccountFallback";
import { scrollTop } from "../../layout/scripts/scroll";
import { getLangCountry } from "../../router/scripts/link";
import Right from "../../layout/components/Col/Right";
import CartOverlay from "../../cart/components/CartOverlay";
import ConsignmentStockOrderHeader from "./ConsignmentStockOrderHeader";
import { OrderType } from "../../common/constants/OrderType";
import { useSyncShippingMethod } from "../../checkout/hooks/shippingMethods";
import { useInitConsignmentStockCheckout } from "consignment-stock/hooks";
import useInitCustomer from "../../account/hooks/useInitCustomer";

const ConsignmentStockVerification: React.FC = () => {
  const params = useParams();
  const country = useCountry();
  const consignmentStockOrderId = params[PARAMS.ORDER_ID];
  const consignmentStock: ConsignmentStock = useSelector((state: State) => state.consignmentStock);
  const customer: Customer | null = useSelector((state: State) => state.account.customer);
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);

  const shippingAddress: Addresses | null = getShippingAddress(
    consignmentStock.order,
    consignmentStock.order.addresses,
  );
  const billingAddress: Addresses | null = getBillingAddress(
    consignmentStock.order,
    consignmentStock.order.addresses,
  );

  const shippingMethod: ShippingMethods | null = getShippingMethod(consignmentStock.order);
  const paymentMethod: PaymentMethods | null = getPaymentMethod(consignmentStock.order);

  useInitConsignmentStockCheckout();
  useInitCustomer();
  useSyncShippingMethod(OrderType.CST_ORDER);

  if (customer === null) {
    return <AccountFallback />;
  }

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeaderAccount activeLi={PATH.CONSIGNMENT_STOCK} customerId={customer.id} />
        <Left>
          <NavAccount activeLi={PATH.CONSIGNMENT_STOCK} customerId={customer.id} />
        </Left>
        <Middle>
          <h1 className="col-title">{i18next.t("CONSIGNMENT STOCK")}</h1>
          <ConsignmentStockBreadcrumb activeStep={3} />

          <OrderAddressSection
            orderId={consignmentStockOrderId}
            orderType={OrderType.CST_ORDER}
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            showEditLink={true}
            customerId={customer.id}
          />

          <OrderShippingPaymentSection
            orderId={consignmentStockOrderId}
            orderType={OrderType.CST_ORDER}
            shippingMethod={shippingMethod}
            paymentMethod={paymentMethod}
            showEditLink={true}
            customerId={customer.id}
          />

          <div className="grid-wrap grid-wrap--checkout">
            <div className="checkout-account-wrap">
              <h2 className="col-title">{i18next.t("PRODUCT LIST")}</h2>
              <div className="cart cart--checkout ">
                <div className="cart-wrap">
                  <ConsignmentStockOrderHeader />
                  {categoriesTree.map((category, index) => (
                    <LineItemList
                      cartId={consignmentStock.order.id}
                      category={category}
                      lineItems={consignmentStock.order.lineItemsSkus}
                      showUnitAmount={false}
                      showBatchNumber={true}
                      showAvailabilityAlert={false}
                      quantityIsEditable={false}
                      key={index}
                    />
                  ))}
                  <LineItemList
                    cartId={consignmentStock.order.id}
                    category={null}
                    lineItems={consignmentStock.order.lineItemsSkus}
                    showUnitAmount={false}
                    showBatchNumber={true}
                    showAvailabilityAlert={false}
                    quantityIsEditable={false}
                    key={-1}
                  />
                </div>

                {consignmentStock.order.attributes !== null && (
                  <div className="cart-checkout">
                    <div className="grid-1-2" />
                    <div className="grid-1-2">
                      <OrderSumList order={consignmentStock.order} />
                    </div>
                    <div className="cart-checkout__total-wrap">
                      <div className="grid-1-2" />
                      <div className="grid-1-2">
                        <OrderTotal order={consignmentStock.order} />
                        <div className="input--checkbox-wrap reverse">
                          <input
                            type="checkbox"
                            className="input--checkbox"
                            id="gtc"
                            name="gtc"
                            aria-label={`${i18next.t("AGREE TO GTC 0")}${i18next.t(
                              "AGREE TO GTC 1",
                            )}${i18next.t("AGREE TO GTC 2")}`}
                            aria-required="true"
                            aria-checked={consignmentStock.checkout.gtc}
                            checked={consignmentStock.checkout.gtc}
                            onChange={e => setGtcAction(e.target.checked)}
                          />
                          <label className="label label--checkbox" htmlFor="gtc">
                            {i18next.t("AGREE TO GTC 0")}
                            {i18next.exists(CountrySpecific[country].gtcUrlKey) ? (
                              <a
                                href={i18next.t(CountrySpecific[country].gtcUrlKey)}
                                target="_blank"
                                rel="noreferrer"
                                title={i18next.t("AGREE TO GTC 1")}
                              >
                                <span>{i18next.t("AGREE TO GTC 1")}</span>
                              </a>
                            ) : (
                              <span>{i18next.t("AGREE TO GTC 1")}</span>
                            )}
                            {i18next.t("AGREE TO GTC 2")}
                          </label>
                        </div>
                        {!consignmentStock.checkout.gtc &&
                          consignmentStock.checkout.gtcError !== null && (
                            <span className="form-error">{consignmentStock.checkout.gtcError}</span>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="btn-wrap btn-wrap--line">
            <Link
              to={`${getLangCountry()}/${PATH.ACCOUNT}/${customer.id}/${PATH.CONSIGNMENT_STOCK}`}
              className="btn transparent"
              role="button"
              tabIndex={0}
              onClick={() => scrollTop()}
            >
              <span className="btn__text">{i18next.t("PRODUCT SELECTION")}</span>
            </Link>

            {!paymentMethod && !consignmentStock.checkout.orderUpdating && (
              <button className="btn transparent" tabIndex={0}>
                <span className="btn__text">{`${i18next.t("NO PAYMENT OPTION")}`}</span>
              </button>
            )}
            {paymentMethod &&
              !consignmentStock.checkout.orderProcessing &&
              !consignmentStock.checkout.orderUpdating && (
                <button className="btn " onClick={placeConsignmentStockOrder} tabIndex={0}>
                  <span className="btn__text">
                    {i18next.t("OBTAIN PRODUCT", {
                      count: consignmentStock.order.lineItemsSkus.length,
                    })}
                  </span>
                </button>
              )}
            {consignmentStock.checkout.orderProcessing && <OrderProcessingButton />}
            {consignmentStock.checkout.orderUpdating && (
              <button className="btn transparent" tabIndex={0}>
                <span className="btn__text">{`${i18next.t("CHECKOUT IS UPDATED")} ...`}</span>
              </button>
            )}
          </div>
        </Middle>
        <Right removeOnCartClosed={true}>
          <CartOverlay />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default ConsignmentStockVerification;
