import React, { Dispatch, SetStateAction } from "react";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useLinkToCategoryBuilder } from "../../category/scripts/link";
import { BreadcrumbDropdownValue } from "./BreadcrumbDropdown";
import BreadcrumbItem from "./BreadcrumbItem";
import { hasDisplayableItems } from "../../category/scripts/filter";
import { useParams } from "react-router-dom";

interface Props {
  mainProductGroup: ProductGroup;
  setDropDownOpen: Dispatch<SetStateAction<boolean>>;
}

const BreadcrumbSubCategory: React.FC<Props> = ({ mainProductGroup, setDropDownOpen }) => {
  const params = useParams();
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const { breadcrumbCategories } = mainProductGroup;
  const getLinkToCategory = useLinkToCategoryBuilder();

  let subCategories: Category[] = [];

  // subCategories are only relevant when the first two breadcrumbCategories are provided
  if (breadcrumbCategories?.[1] && breadcrumbCategories?.[0]) {
    subCategories =
      categoriesTree.find(category => category?.id === breadcrumbCategories?.[0])?.categories || [];
  }

  // abort when no subCategories found
  if (!subCategories || subCategories.length === 0) {
    return null;
  }

  const getTitle = () =>
    subCategories.find(category => category.id === breadcrumbCategories[1])?.name || "";

  const getDropdownValues = (): BreadcrumbDropdownValue[] =>
    subCategories
      .filter(category => hasDisplayableItems(category, params))
      .map(category => ({
        title: category.name,
        to: getLinkToCategory(category),
        active: category.id === breadcrumbCategories[1],
      }));

  return (
    <BreadcrumbItem
      title={getTitle()}
      setDropDownOpen={setDropDownOpen}
      dropdownValues={getDropdownValues()}
    />
  );
};

export default BreadcrumbSubCategory;
