import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Left from "../../layout/components/Col/Left";
import Right from "../../layout/components/Col/Right";
import i18next from "i18next";
import CartOverlay from "../../cart/components/CartOverlay";
import NavAccount from "./NavAccount";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SearchOverlay from "../../search/components/SearchOverlay";
import MobileHeaderAccount from "./MobileHeaderAccount";
import OrderDocument from "../../state/vo/OrderDocument";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import { getDateFormatted } from "../../common/scripts/format/date";
import AccountReportOrderDocument from "./AccountReportOrderDocument";
import AccountReportForm from "./AccountReportForm";
import { AccountFallback } from "./AccountFallback";
import useInitCustomer from "../hooks/useInitCustomer";

const AccountReport: React.FC = () => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const accountReport = useSelector((state: State) => state.account.report);
  useInitCustomer();

  if (accountCustomer === null) {
    return <AccountFallback />;
  }

  const customerId = accountCustomer.id;

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeaderAccount activeLi={PATH.REPORT} customerId={accountCustomer.id} />
        <Left>
          <NavAccount activeLi={PATH.REPORT} customerId={customerId} />
        </Left>
        <Middle>
          <h1 className="col-title">
            {i18next.t("REPORT")}
            {accountReport !== null &&
              ` ${getDateFormatted(accountReport.dateFrom)} - ${getDateFormatted(
                accountReport.dateTo,
              )}`}
          </h1>
          <div className="block-title block-title--underline date-filter-title">
            {i18next.t("FILTER")}
          </div>
          <AccountReportForm customerId={customerId} />
          {accountReport !== null && accountReport.data.length > 0 && (
            <div className="list-wrap">
              <div className="list-4cols list-4cols--header">
                <div className="list__col" />
                <div className="list__col">{i18next.t("DATE")}</div>
                <div className="list__col">{i18next.t("VAT")}</div>
                <div className="list__col">{i18next.t("TOTAL")}</div>
              </div>
              {accountReport.data.map((orderDocument: OrderDocument, index: number) => {
                return (
                  <AccountReportOrderDocument
                    customerId={customerId}
                    orderDocument={orderDocument}
                    key={orderDocument.salesDocumentNumber}
                  />
                );
              })}
              <div className="list-4cols list-4cols--summery space-top">
                <div className="list__col col-1-4">{i18next.t("TOTAL")}</div>
                <div className="list__col col-5-6">
                  {getNumberWithCurrency(
                    accountReport.grandTotalAmount,
                    accountReport.totalCurrency,
                  )}
                </div>
              </div>
              <div className="list-4cols list-4cols--summery space-top">
                <div className="list__col col-1-4">
                  {i18next.t("TOTAL CONSUMABLES WITHOUT VAT")}
                </div>
                <div className="list__col col-5-6">
                  {getNumberWithCurrency(
                    accountReport.totalConsumableMaterialsAmount,
                    accountReport.totalCurrency,
                  )}
                </div>
              </div>
              <div className="list-4cols list-4cols--summery">
                <div className="list__col col-1-4">
                  {i18next.t("TOTAL CAPITAL GOODS WITHOUT VAT")}
                </div>
                <div className="list__col col-5-6">
                  {getNumberWithCurrency(
                    accountReport.totalInvestmentGoodsAmount,
                    accountReport.totalCurrency,
                  )}
                </div>
              </div>
              <div className="list-4cols list-4cols--summery">
                <div className="list__col col-1-4">{i18next.t("TOTAL WITHOUT VAT")}</div>
                <div className="list__col col-5-6">
                  {getNumberWithCurrency(
                    accountReport.totalConsumableMaterialsAmount +
                      accountReport.totalInvestmentGoodsAmount,
                    accountReport.totalCurrency,
                  )}
                </div>
              </div>
            </div>
          )}
        </Middle>
        <Right removeOnCartClosed={true}>
          <CartOverlay />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default AccountReport;
