import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { PARAMS } from "../../router/constants/Params";
import { initCustomer } from "../../salesagent/actions/salesagentActions";

const useInitCustomer = () => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const params = useParams();

  useEffect(
    function handleInitAccountCustomer() {
      if (accountCustomer === null) {
        if (params[PARAMS.ACCOUNT_ID]) {
          initCustomer(params[PARAMS.ACCOUNT_ID]);
        }
      }
    },
    [accountCustomer, params],
  );
};

export default useInitCustomer;
