import { Category, Product, ProductGroup } from "../../graphcms/vo/graphCMS";
import { PATH } from "../../router/constants/Path";
import { getLangCountry } from "../../router/scripts/link";
import { FALLBACK, PARAMS } from "../../router/constants/Params";
import { useLangCountry } from "../../router/hooks/useLangCountry";

function getLinkToCategory(langCountry: string, category: Category): string {
  return `${langCountry}/${PATH.CATEGORY}/${category.id}/${encodeURIComponent(category.name)}`;
}

export function useLinkToCategoryBuilder() {
  const langCountry = useLangCountry();
  return (category: Category) => getLinkToCategory(langCountry, category);
}

export function getLinkToProductGroup(
  categoryId = FALLBACK[PARAMS.CATEGORY_ID],
  productGroup: ProductGroup,
): string {
  return `${getLangCountry()}/${PATH.CATEGORY}/${categoryId}/${PATH.PRODUCTGROUP}/${
    productGroup?.id || FALLBACK[PARAMS.PRODUCTGROUP_ID]
  }/${encodeURIComponent(productGroup?.slug || FALLBACK[PARAMS.PRODUCTGROUP_SLUG])}`;
}

export function getLinkToDirectProduct(product: Product): string {
  return `${getLangCountry()}/${PATH.PRODUCT}/${product?.sku}/${encodeURIComponent(
    product?.slug || FALLBACK[PARAMS.PRODUCT_SLUG],
  )}`;
}
