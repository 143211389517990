import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { getStage } from "./client";
import i18next from "i18next";
import { Locale } from "../vo/graphCMS";
import { HYGRAPH_ENDPOINT } from "../../common/scripts/config/configuration";

function prepareHeaders(headers: Headers): Headers {
  headers.set("gcms-locales", `${i18next.resolvedLanguage}, ${Locale.De}`);
  headers.set("gcms-stage", getStage());
  return headers;
}

// base api for all Hygraph queries
export const hygraphApi = createApi({
  reducerPath: "hygraphApi",
  baseQuery: graphqlRequestBaseQuery({
    url: HYGRAPH_ENDPOINT,
    prepareHeaders,
  }),
  // use this tag to invalidate query whenever language or countryCode changes
  tagTypes: ["languageDependent", "countryCodeDependent"],
  endpoints: () => ({}),
});
