import { initCategoriesTree, initProductGroups, initProducts } from "graphcms/services/init";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useCountry } from "../../router/hooks/useCountry";

function initProductRelated(country: string) {
  initProductGroups(country).then();
  initProducts(country).then();
}

export function useInitGraphCMS() {
  const country = useCountry();
  const { i18n } = useTranslation();

  // use language from i18n and not from params. graphcms will use i18n.language as param
  const { language } = i18n;

  // on country change or on language change
  useEffect(() => {
    // prevents calling this effect on initial render when i18n.language is undefined
    // https://3.basecamp.com/3101128/buckets/19695186/todos/3594241983
    if (!language) {
      return;
    }

    if (!country) {
      return;
    }

    initCategoriesTree().then();
    initProductRelated(country);
  }, [language, country]);
}
