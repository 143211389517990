import { gql } from "graphql-request";
import { hygraphApi } from "./hygraphApi";
import { Pf } from "../vo/graphCMS";
import { pfsFields } from "./init";

export const pfApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    getPfs: builder.query<Pf[], void>({
      providesTags: ["languageDependent"],
      query: () => {
        return {
          document: gql`
            ${pfsFields}
            query GetPfs {
              pfs(orderBy: name_ASC) {
                ...pfsFields
              }
            }
          `,
        };
      },
      transformResponse: (response: { pfs: Pf[] | null }) => response?.pfs ?? [],
    }),
  }),
});

export const { useGetPfsQuery } = pfApi;
