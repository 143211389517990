import { getCountry } from "./params";
import { Product } from "../../graphcms/vo/graphCMS";
import { isDirectProductView } from "./path";
import { getProductGroupIdWhileDirectLink } from "../../graphcms/scripts/productGroup";
import { getRelatedCategoryIdFromProductGroup } from "../../graphcms/scripts/category";
import { getProductIndirectLink } from "../../product/scripts/link";
import { FALLBACK, PARAMS } from "../constants/Params";
import { getLangWithFallback } from "../../i18next/scripts/language";
import { To, useParams } from "react-router-dom";
import { useLangCountry } from "../hooks/useLangCountry";

// because this is a very core part of the URL the `/lang/country`
// combination is provided as a shorthand here
// todo: the goal is to deprecate this with useLangCountry()
export function getLangCountry() {
  const lang = getLangWithFallback();
  const country = getCountry();
  return `/${lang}/${country}`;
}

function getProductLink(product: Product, params: any, langCountry: string): To {
  const fallback = { pathname: "", search: window.location.search };

  // case direct product
  if (isDirectProductView()) {
    const productGroupId = getProductGroupIdWhileDirectLink(params);

    if (!productGroupId) {
      return fallback;
    }

    const relatedCategoryId = getRelatedCategoryIdFromProductGroup(productGroupId);

    return getProductIndirectLink(langCountry, relatedCategoryId, productGroupId, product);
  } else {
    // case when in an indirect product link
    // case when coming from categories pages. same as having selected no product at all or using breadcrumb to switch
    const categoryId = params[PARAMS.CATEGORY_ID] ?? FALLBACK[PARAMS.CATEGORY_ID];
    const productGroupId = params[PARAMS.PRODUCTGROUP_ID] ?? FALLBACK[PARAMS.PRODUCTGROUP_ID];

    return getProductIndirectLink(langCountry, categoryId, productGroupId, product);
  }
}

export function useGetProductLinkBuilder() {
  const params = useParams();
  const langCountry = useLangCountry();

  return (product: Product): To => getProductLink(product, params, langCountry);
}
