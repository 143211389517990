import { ProductGroup } from "../../../graphcms/vo/graphCMS";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { devLogInfo } from "../../../common/scripts/logger/log";
import { isProductGroupView } from "../../scripts/path";
import { useGetProductLinkBuilder } from "../../scripts/link";

interface Props {
  mainProductGroup: ProductGroup;
}

const RepSingleProduct: React.FC<Props> = ({ mainProductGroup }) => {
  const params = useParams();
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = useState(location.pathname);
  const getProductLink = useGetProductLinkBuilder();
  const navigate = useNavigate();

  useEffect(() => {
    // only relevant for this view
    if (!isProductGroupView()) {
      return;
    }

    if (!mainProductGroup) {
      return;
    }

    // only relevant  when productGroup has a single product
    if (mainProductGroup.products.length > 1) {
      return;
    }

    const product = mainProductGroup.products[0];
    const productLink = getProductLink(product);

    if (typeof productLink === "string") {
      return;
    }

    if (!productLink?.pathname) {
      return;
    }

    // abort replacing same one, prevents infinite loop
    if (productLink.pathname === currentPathname) {
      return;
    }

    setCurrentPathname(productLink.pathname);
    navigate(productLink, { replace: true });
    devLogInfo("RepSingleProduct", "replace", productLink);
  }, [currentPathname, getProductLink, location, mainProductGroup, navigate, params]);

  return null;
};

export default RepSingleProduct;
