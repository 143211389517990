import React from "react";
import { Category } from "../../graphcms/vo/graphCMS";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { useLinkToCategoryBuilder } from "../scripts/link";
import NavListItemSub from "./NavListItemSub";
import { isOpenCategory } from "../scripts/navigation";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const NavListItem: React.FC<{ category: Category }> = ({ category }) => {
  const params = useParams();
  const langCountry = useLangCountry();
  const getLinkToCategory = useLinkToCategoryBuilder();

  // having children either categories or productGroups
  const hasSub = category?.categories?.length > 0 || category?.productGroups?.length > 0;

  // open when main category or one of the children is currently active
  const open = isOpenCategory(category, params);
  const openClass = open ? "open" : "";

  // show active marker on this level only when it's the main category
  const showActiveMarker = category.id === params[PARAMS.CATEGORY_ID];
  const activeMarkerClass = showActiveMarker ? "active" : "";

  const cls = `nav-list__item${hasSub ? "--has-sub" : ""} ${activeMarkerClass} ${openClass}`;

  return (
    <li className={cls}>
      {showActiveMarker ? (
        <h1
          className="nav-list__title nav-list__link nav-list__title--bold"
          onClick={() => boundPushLocObj({ pathname: langCountry })}
        >
          {category.name ?? ""}
        </h1>
      ) : (
        <Link
          className="nav-list__link nav-list__link--bold"
          to={getLinkToCategory(category)}
          onClick={() => scrollTop()}
        >
          {category.name ?? ""}
        </Link>
      )}
      <NavListItemSub category={category} />
    </li>
  );
};

export default NavListItem;
