import React from "react";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import {
  isAccountView,
  isCartView,
  isCategoryView,
  isCheckoutView,
  isConfiguratorView,
  isIndirectProductView,
  isLoginView,
  isOrderView,
  isProductGroupView,
  isProductView,
  isScanToCartView,
  isSearchView,
  isSignupView,
} from "../../router/scripts/path";
import { useSelectedProduct } from "../../product/hooks/product";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import OgTagsAndTwitterCards from "./OgTagsAndTwitterCards";
import {
  getProductGroupStructuredData,
  getProductStructuredData,
  getStructuredDataImage,
  getStructuredDataProductGroupName,
  getUrl,
} from "../scripts/structuredData";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { getLinkToProductGroup, useLinkToCategoryBuilder } from "../../category/scripts/link";
import { getCategoryId } from "../../router/scripts/params";
import Alternate from "./Alternate";
import Canonical from "./Canonical";
import { getRelatedCategory } from "../../graphcms/scripts/category";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { HYGRAPH_ASSET_ORIGIN } from "../../common/scripts/config/configuration";

const PageMetaData: React.FC = () => {
  const params = useParams();
  const langCountry = useLangCountry();
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const productGroupsIndex = useSelector((state: State) => state.graphcms.productGroupsIndex);
  const productGroup = productGroupsIndex?.[params[PARAMS.PRODUCTGROUP_ID] ?? ""] ?? "";
  const products = useSelector((state: State) => state.graphcms.products);
  const product = useSelectedProduct();
  const order = useSelector((state: State) => state.order);
  const getLinkToCategory = useLinkToCategoryBuilder();

  const separator = "| ";
  const origin: string = window.location.origin;

  let pageTitle: string = "";
  let ogType: string = "";
  let imageUrl: string | null = null;
  let url: string = window.location.href;

  if (isLoginView()) {
    pageTitle += `${i18next.t("LOGIN")} ${separator}`;
    url = "";
  } else if (isProductView() || isIndirectProductView()) {
    if (product && productGroup) {
      pageTitle = `${product?.name} ${product?.nameSuffix ?? ""} ${i18next.t(
        "BUY ONLINE",
      )} ${separator}`;
      ogType = "product";
      imageUrl = getStructuredDataImage(productGroup, product);
      url = getUrl(productGroup, product);
    }
  } else if (isProductGroupView()) {
    if (productGroup) {
      pageTitle = `${getStructuredDataProductGroupName(productGroup)} ${separator}`;
      ogType = "website";
      imageUrl = getStructuredDataImage(productGroup, null);
      url = `${origin}${getLinkToProductGroup(getCategoryId(), productGroup)}`;
    }
  } else if (isCategoryView()) {
    if (params[PARAMS.CATEGORY_ID]) {
      const parentId = params[PARAMS.PRODUCTGROUP_ID] ?? params[PARAMS.CATEGORY_ID] ?? "";
      const category = getRelatedCategory(categoriesTree, parentId);
      if (category) {
        pageTitle += `${category.name} ${i18next.t("BUY ONLINE")} ${separator}`;
        ogType = "website";
        imageUrl =
          `${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:300,width:300/${category.image?.handle}` ??
          null;
        url = `${origin}${getLinkToCategory(category)}`;
      }
    }
  } else if (isAccountView()) {
    pageTitle += `${i18next.t("ACCOUNT")} ${separator}`;
    url = "";
  } else if (isCartView()) {
    pageTitle += `${i18next.t("CART")} ${separator}`;
  } else if (isCheckoutView()) {
    pageTitle += `${i18next.t("CHECKOUT")} ${separator}`;
  } else if (isConfiguratorView()) {
    pageTitle += `${i18next.t("CONFIGURATOR")} ${separator}`;
  } else if (isOrderView()) {
    pageTitle += `${i18next.t("ORDER", { count: 1 })} #${order.attributes?.number} ${separator}`;
  } else if (isScanToCartView()) {
    pageTitle += `${i18next.t("SCAN-TO-CART")} ${separator}`;
  } else if (isSearchView()) {
    pageTitle += `${i18next.t("SEARCH RESULT", { count: 0 })} ${separator}`;
  } else if (isSignupView()) {
    pageTitle += `${i18next.t("SIGNUP")} ${separator}`;
  } else {
    pageTitle += "";
    ogType = "";
  }

  pageTitle += i18next.t("PAGE TITLE SUFFIX");

  const structuredDataWebsite = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    id: `${origin}${langCountry}/#website`,
    url: `${origin}${langCountry}`,
    potentialAction: {
      "@type": "SearchAction",
      target: `${origin}${langCountry}/{search_term}`,
      "query-input": "required name=search_term",
    },
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>

        <meta name="description" content={pageTitle} />
        <script type="application/ld+json">{JSON.stringify(structuredDataWebsite)}</script>

        {isProductGroupView() && productGroup && (
          <script type="application/ld+json">
            {JSON.stringify(getProductGroupStructuredData(productGroup, products, true))}
          </script>
        )}
        {(isProductView() || isIndirectProductView()) && product && productGroup && (
          <script type="application/ld+json">
            {JSON.stringify(getProductStructuredData(productGroup, product, true))}
          </script>
        )}
      </Helmet>
      <Alternate />
      <Canonical />
      {!isOrderView() && !isAccountView() && !isCartView() && !isCheckoutView() && (
        <OgTagsAndTwitterCards
          pageTitle={pageTitle}
          imageHandle={imageUrl}
          ogType={ogType}
          url={url}
        />
      )}
    </>
  );
};
export default PageMetaData;
