import { setIsMobile } from "../../../layout/slices/layoutSlice";
import { getIsMobile } from "../../../state/init/layout";
import { useEffect } from "react";
import debounce from "../helpers/debounce";

function handleIsMobile() {
  setIsMobile(getIsMobile());
}

export const useHandleIsMobile = () => {
  useEffect(() => {
    if ("ResizeObserver" in window) {
      const ro = new ResizeObserver(debounce(handleIsMobile, 50));

      // defensive disconnect
      ro.disconnect();

      if (!document.body) {
        return;
      }

      ro.observe(document.body);

      // cleanup
      return () => ro.disconnect();
    }
  }, []);
};
