import React from "react";
import { HYGRAPH_ASSET_ORIGIN } from "../../common/scripts/config/configuration";

export interface CommonImgProps {
  handle?: string;
  alt?: string | null;
}

export const ImgTwoCols: React.FC<CommonImgProps> = ({ handle = null, alt = "" }) => {
  if (handle === null) {
    return null;
  }

  return (
    <picture>
      <source
        media="screen and (min-width:1440px)"
        srcSet={`${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:151,width:151/${handle} 1x, ${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:302,width:302/${handle} 2x`}
      />
      <source
        media="screen and (min-width:1200px) and (max-width:1439px)"
        srcSet={`${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:129,width:129/${handle} 1x, ${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:258,width:258/${handle} 2x`}
      />
      <source
        media="screen and (max-width:1199px)"
        srcSet={`${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:280,width:280/${handle} 1x, ${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:560,width:560/${handle} 2x`}
      />
      <img
        className="img"
        alt={alt ?? ""}
        src={`${HYGRAPH_ASSET_ORIGIN}/auto_image/resize=height:151,width:151/${handle}`}
      />
    </picture>
  );
};
