import { Product } from "../../graphcms/vo/graphCMS";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import { FALLBACK, PARAMS } from "../../router/constants/Params";
import { To } from "react-router-dom";

export function getProductIndirectLink(
  langCountry: string,
  categoryId: string,
  productGroupId: string,
  product: Product,
): To {
  const pathname =
    langCountry +
    `/${PATH.CATEGORY}` +
    `/${categoryId}` +
    `/${PATH.PRODUCTGROUP}` +
    `/${productGroupId}` +
    `/${PATH.PRODUCT}` +
    `/${product?.sku || FALLBACK[PARAMS.SKU]}` +
    `/${product?.slug || FALLBACK[PARAMS.PRODUCT_SLUG]}`;

  return { pathname, search: window.location.search };
}

export function getProductDirectLocationObject(product: Product): To {
  const pathname = getProductDirectLinkSkuSlug(product?.sku, product?.slug);

  return { pathname, search: window.location.search };
}

export function getProductDirectLinkSkuSlug(
  sku: string | null | undefined,
  slug: string | null | undefined,
): string {
  sku = sku || FALLBACK[PARAMS.SKU];
  slug = slug || FALLBACK[PARAMS.PRODUCT_SLUG];

  return `${getLangCountry()}/${PATH.PRODUCT}/${sku}/${slug}`;
}
